import React, { useEffect, useRef } from "react";
import "./Header.css";
import logo2 from "../assets/logo-text.png";
import force from "../assets/1.png";
import eternite from "../assets/3.png";
import loyaute from "../assets/2.png";
import gsap from "gsap";
import { FaTwitter } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";
import ScrollTrigger from "gsap/ScrollTrigger";

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const images = importAll(require.context('../assets/frames', false, /\.(png|jpe?g|svg)$/));

function Header() {
   
    gsap.registerPlugin(ScrollTrigger)

    const videoContainerRef = useRef(null)

    let imagez = {};

    useEffect(() => {
        const canvas = document.getElementById("global_canvas");


        function loadImages(start,end) {
            var loadedImageCount = 0;

            // Make sure arr is actually an array and any other error checking
            for (var i = start; i < end; i++){

                var img = new Image();
                img.onload = imageLoaded;
                let finalFinalImage = ''
                    if (i <= 9) {
                        finalFinalImage = '000' + i
                    } else if (i <= 99) {
                        finalFinalImage = '00' + i
                    } else if (i >= 100) {
                        finalFinalImage = '0' + i
                    }
        
                img.src = images[`${finalFinalImage}.jpg`];
                imagez[i] = img;
            }
        
            function imageLoaded(e) {
                loadedImageCount++;
                if (loadedImageCount >= 200) {
                    
                }
            }
        }

        loadImages(0,50);
        loadImages(51,100);
        loadImages(101,150);
        loadImages(151,200);
      
        canvas.width = 1520;
        canvas.height = 1080;

        function drawCanvasImg(e) {

            const image = new Image();
            let currentFrame = e.frame * e.endFrame;
            let finalFrame = currentFrame.toFixed(0);

            let finalFinalImage = ''
            if (finalFrame <= 9) {
                finalFinalImage = '000' + finalFrame
            } else if (finalFrame <= 99) {
                finalFinalImage = '00' + finalFrame
            } else if (finalFrame >= 100) {
                finalFinalImage = '0' + finalFrame
            }

            image.src = images[`${finalFinalImage}.jpg`];

            image.onload = function () {
                var canvasContext = canvas.getContext('2d');
                var wrh = image.width / image.height;
                var newWidth = canvas.width;
                var newHeight = newWidth / wrh;
                if (newHeight > canvas.height) {
                    newHeight = canvas.height;
                    newWidth = newHeight * wrh;
                }
                var xOffset = newWidth < canvas.width ? ((canvas.width - newWidth) / 2) : 0;
                var yOffset = newHeight < canvas.height ? ((canvas.height - newHeight) / 2) : 0;

                canvasContext.drawImage(image, xOffset, yOffset, newWidth, newHeight);
           }

            return {
                currentFrame: currentFrame,
            };
        }

        // const controller = new ScrollMagic.Controller()
        // const scene = new ScrollMagic.Scene({
        //     triggerElement: videoContainerRef.current,
        //     duration: 500,
        //     triggerHook: 0
        // }).addTo(controller).setPin(videoContainerRef.current)

        // scene.on('update', (e) => {
        //     let frame = e.scrollPos / 500
        //      console.log(frame);
        //     drawCanvasImg({
        //         frame: frame,
        //         startFrame: 1,
        //         endFrame: 227,
        //   })
        // })

      gsap.to(".homepage-container", {
            scrollTrigger: {
                trigger: ".homepage-container",
                pin: true,
               start: "top top",
                end: "bottom top",
                markers: false,
                scrub: true,
                onUpdate: function (tl) {
                    let progress = tl.progress;
                    drawCanvasImg({
                        frame: progress,
                        startFrame: 1,
                        endFrame: 200,
                    })
                },
            },
        });

    }, [])

    return (
        <div className='homepage-container'>
            <nav className="navbar is-transparent ">
  <div className="navbar-brand">
    <a rel="noreferrer" className="navbar-item" href="/">
    <img id='l-img' src={logo2} alt='logo'/>
    </a>
    <div  className="navbar-burger  is-grouped mt-5" >
                        <a id="twitter-mobile" rel="noreferrer" target="_blank" href="https://twitter.com/shinsekaicorp">
                            <FaTwitter  style={{fill : 'white',width: '100%',fontSize:'25px',marginBottom:'5%'}}/>
                        </a>
                      
                        <a rel="noreferrer" target="_blank" href='https://t.co/bUper2SbeI' >
                            <FaDiscord style={{fill : 'white',width: '100%',fontSize:'25px'}}/>
                        </a>
    </div>
  </div>

  <div id="navMenu" className="navbar-menu ">

    <div className="navbar-end">
      <div className="navbar-item">
        <div className="field is-grouped">
                    <div className='link-twitter-container'>
                        <img alt="force" src={force} id='force-image'/>
                        <a rel="noreferrer" target="_blank" href='https://twitter.com/shinsekaicorp' id='twitter' >
                            Twitter
                        </a>
                    </div>
                    <div className='link-twitter-container'>
                        <img alt="force" src={loyaute} id='force-image'/>
                        <a rel="noreferrer" target="_blank" href='https://t.co/bUper2SbeI' id='discord' >
                            Discord
                        </a>
                    </div>

                    <div className='link-opensea-container'>
                        <img alt="eternite" src={eternite} id='eternite-image'/>
                        <a rel="noreferrer"   href='https://opensea.io/OfficialShinsekaiCorp' target="_blank" id='opensea'>
                            View On Opensea
                        </a>
                    </div> 
                </div>
      </div>
    </div>
  </div>
</nav>
            <div className='header-container'>
                <div className="but-vid">
                    <div className="video-container" ref={videoContainerRef}>
                        <canvas  id="global_canvas" ></canvas>
                    </div>
                </div>
                <div className='buttons-mangaverse'>
                    <a rel="noreferrer" id='but-mangaverse' target="_blank" href='https://t.co/bUper2SbeI'>
                        JOIN THE MANGAVERSE
                    </a>
                </div>
            
            </div>
        </div>

    );
}


export default Header;