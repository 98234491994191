import React from "react";
import ReactGA from 'react-ga';
import './App.css';
import Header from './components/Header';
import Critere from './components/Critere';
import How7 from './components/How7';
import Team from './components/Team';
import Faq from './components/Faq';
import Footer from './components/Footer';
import Timeline from './components/Timeline';
import RoadMap from './components/RoadMap';
import 'animate.css';
import Universe from './components/Universe';
import AppelAction from './components/AppelAction';

function App() {  
  
  ReactGA.initialize('306832894');
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="App">
        <Header/>
        <Critere/>
        <How7/>
        <Timeline/>
        <Universe/>
        <RoadMap/>
        <Team/>
        <AppelAction/>
        {/* <Faq/> */}
        <Footer/>
    </div>
  );
}

export default App;
