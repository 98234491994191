import React from "react";
import "./AppelAction.css";
import { FaDiscord} from "react-icons/fa";
import vid from "../assets/back-appel.gif";
import { useEffect } from "react";
import gsap from "gsap";






function AppelAction(){
    
    
    
    useEffect(()=>{
        // const target = '.appel-action-container div'

        // // You can set your initial animation.
        // // Like i set opacity 0 for this.
        // gsap.set(target, {
        //     opacity: 0
        // })

        // // And here all the div is looping through
        // document.querySelectorAll(target).forEach((eachDiv, index) => {
        //     gsap.to(eachDiv, {
        //         scrollTrigger: {
        //             trigger: eachDiv,
        //             start: "top 75%",
        //             end: "top center",
        //             marker: true,
        //             scrub: true,
        //         },
        //         duration: 200,
        //         // Here you will set your animation, That need to play when they reached the viewport.
        //         opacity: 1
        //     })
        // })

    },[])



    return(
        <div className="appel-action-container">

            <div className="video-appele-action">
                {/* <video  autoPlay loop muted playsInline >
                    <source src={vid} type='video/mp4'/>
                </video> */}
                <img src={vid} alt='apel-action'/>
            </div>
            <div className="text-appel-action-container">
                <p className="appel-action-text">
                    BE PART OF THE MANGAVERSE
                </p>
                <a id="dis-appel-but" rel="noreferrer" target="_blank" href="https://t.co/bUper2SbeI">
                        <FaDiscord id="discord-icone-appel" style={{fill : 'white'}}/>
                </a>
            </div>
        </div>
    );
}

export default AppelAction;